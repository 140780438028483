import React, { ChangeEvent, FC, useCallback, useState } from "react";
import styled, { css } from "styled-components";
import Box from "@mui/material/Box";
import { InputAdornment, SelectChangeEvent } from "@mui/material";
import { BoxProps } from "@mui/material/Box/Box";
import dayjs from "dayjs";
import { Duration, DurationUnitsObjectType } from "dayjs/plugin/duration";
import SpacedTextInput, { SpacedDropDownSelect } from "./SpacedTextInput";
import SpacedTextField from "./SpacedTextField";

/**
 * Interface for time duration cell with external adornment
 */
interface StyledTimeDurationFieldCellProps extends BoxProps {
  endAdornmentWidth?: number;
}

/**
 * Styled time duration cell with external adornment
 */
const TimeDurationCellExternalAdornment = styled(Box).withConfig({
  shouldForwardProp: (prop) => !["endAdornmentWidth"].includes(prop),
})<StyledTimeDurationFieldCellProps>`
  display: block;
  min-width: 5rem;
  padding-left: 0.5rem;

  ${({ endAdornmentWidth }) => {
    let width = 0;
    if (endAdornmentWidth) {
      width = endAdornmentWidth;
    }

    return css`
      padding-right: ${width}px;
    `;
  }}
`;

/**
 * Normalize the duration data so it's standardize when converting to ISO 8601 format
 * @param duration The duration to be normalized
 */
const normalizeDuration = (duration: Duration): Duration =>
  dayjs.duration(duration.asMilliseconds());

/**
 * Check if a string or number is non-negative
 * @param v
 */
const isNonNegativeNumber = (v: number | string): boolean =>
  Number(v) ? Number(v) >= 0 : v === "" || Number(v) === 0;

/**
 * Get continued numerical options for a drop-down menu
 * @param numOptions The number of options to generate starting from 0.
 */
const getContinueNumericalOptions = (
  numOptions: number,
): Record<string, number> =>
  Object.fromEntries(
    Array.from({ length: numOptions }, (_, i) => i).map<[string, number]>(
      (i) => [String(i), i],
    ),
  );

/**
 * Constant of hours options 0 - 23
 */
const hoursOptions: Record<string, number> = getContinueNumericalOptions(24);
/**
 * Constant of minutes options 0 - 59
 */
const minsOptions: Record<string, number> = getContinueNumericalOptions(60);

/**
 * Interface for time duration field
 */
interface TimeDurationFieldProps {
  label?: string;
  value?: Duration;
  onChange?: (value: Duration) => void;
}

/**
 * Component of time duration field
 * @param label the label of the field
 * @param value the initial value of the field
 * @param onChange the call back function to call when time duration change
 */
const TimeDurationField: FC<TimeDurationFieldProps> = ({
  label,
  value,
  onChange,
}) => {
  // Data manage by the component
  const [dataDuration, setDataDuration] = useState<Duration>(
    normalizeDuration(value ?? dayjs.duration(0)),
  );

  // Data used for display only
  const [days, setDays] = useState(
    `${!dataDuration.days() ? "" : dataDuration.days()}`,
  );

  /**
   * Handle duration data change
   */
  const handleDurationChange = useCallback(
    (newDurationObject: DurationUnitsObjectType): void => {
      const newDuration = dayjs.duration({
        minutes: newDurationObject.minutes ?? dataDuration.minutes(),
        hours: newDurationObject.hours ?? dataDuration.hours(),
        days: newDurationObject.days ?? dataDuration.days(),
      });
      setDataDuration(newDuration);
      onChange?.(normalizeDuration(newDuration));
    },
    [dataDuration, onChange],
  );

  /**
   * Handle days field on blur
   */
  const handleDaysOnBlur = useCallback(() => {
    if (isNonNegativeNumber(days) && !Number.isInteger(Number(days))) {
      const normalizedDuration = normalizeDuration(dataDuration);
      if (days !== "") {
        setDays(`${normalizedDuration.days()}`);
      }
      setDataDuration(normalizedDuration);
    }
  }, [dataDuration, days]);

  /**
   * Handle days field on change
   */
  const handleDaysChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      setDays(newValue);
      handleDurationChange({
        days: isNonNegativeNumber(newValue) ? Number(newValue) : 0,
      });
    },
    [handleDurationChange],
  );

  /**
   * Handle hours field on change
   */
  const handleHoursChange = useCallback(
    (event: SelectChangeEvent<number>) => {
      const newValue = event.target.value;
      handleDurationChange({ hours: Number(newValue) ?? 0 });
    },
    [handleDurationChange],
  );

  /**
   * Handle minutes field on change
   */
  const handleMinsChange = useCallback(
    (event: SelectChangeEvent<number>) => {
      const newValue = event.target.value;
      handleDurationChange({ minutes: Number(newValue) ?? 0 });
    },
    [handleDurationChange],
  );

  return (
    <Box display="flex" justifyContent="flex-start">
      {!onChange ? (
        <SpacedTextField
          label={label ?? ""}
          value={dataDuration.days()}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: <InputAdornment position="end">d.</InputAdornment>,
          }}
          gray
        />
      ) : (
        <SpacedTextInput
          label={label ?? ""}
          value={days}
          onChange={handleDaysChange}
          onBlur={handleDaysOnBlur}
          error={!isNonNegativeNumber(days)}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: <InputAdornment position="end">d.</InputAdornment>,
          }}
          type="number"
          autoComplete="off"
        />
      )}
      <TimeDurationCellExternalAdornment endAdornmentWidth={24}>
        {!onChange ? (
          <SpacedTextField
            value={dataDuration.hours()}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={{
                    pointerEvents: "none",
                    position: "absolute",
                    right: -24,
                  }}
                >
                  hr.
                </InputAdornment>
              ),
            }}
            gray
          />
        ) : (
          <SpacedDropDownSelect<number>
            name="hours"
            shrink
            options={hoursOptions}
            value={dataDuration.hours()}
            onChange={handleHoursChange}
            endAdornment={
              <InputAdornment
                position="end"
                sx={{
                  pointerEvents: "none",
                  position: "absolute",
                  right: -24,
                }}
              >
                hr.
              </InputAdornment>
            }
          />
        )}
      </TimeDurationCellExternalAdornment>
      <TimeDurationCellExternalAdornment endAdornmentWidth={36}>
        {!onChange ? (
          <SpacedTextField
            value={dataDuration.minutes()}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={{
                    pointerEvents: "none",
                    position: "absolute",
                    right: -36,
                  }}
                >
                  min.
                </InputAdornment>
              ),
            }}
            gray
          />
        ) : (
          <SpacedDropDownSelect<number>
            name="mins"
            shrink
            options={minsOptions}
            value={dataDuration.minutes()}
            onChange={handleMinsChange}
            endAdornment={
              <InputAdornment
                position="end"
                sx={{
                  pointerEvents: "none",
                  position: "absolute",
                  right: -36,
                }}
              >
                min.
              </InputAdornment>
            }
          />
        )}
      </TimeDurationCellExternalAdornment>
    </Box>
  );
};

export default TimeDurationField;
