import {
  FormControl,
  FormControlProps,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { ReactElement, ReactNode } from "react";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";

interface DropDownSelectProps<T> extends Omit<FormControlProps, "onChange"> {
  options: Record<string, T>;
  shrink?: boolean;
  error?: boolean;
  label?: ReactNode;
  name?: string;
  value?: T | "";
  onChange?: (event: SelectChangeEvent<T>) => void;
  endAdornment?: ReactNode;
}

const DropDownSelect = <T,>({
  size,
  options,
  label,
  name,
  value,
  shrink,
  error,
  onChange,
  endAdornment,
  ...props
}: DropDownSelectProps<T>): ReactElement => {
  return (
    <FormControl {...props} size={size} error={error}>
      <InputLabel shrink={shrink}>{label}</InputLabel>
      <Select
        label={label}
        size={size}
        value={value}
        onChange={onChange}
        name={name}
        endAdornment={endAdornment}
      >
        {Object.entries(options).map(([displayName, key]) => (
          <MenuItem key={displayName} value={key as string}>
            {displayName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DropDownSelect;
