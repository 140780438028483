import { FoodTag } from "client/jspPlatformExperiment";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import jspPlatformApi from "client/portals";
import { isApiError, isErrorResponseBodyType } from "models/utils/apiUtils";

interface FoodTagOptionsState {
  data: FoodTag[];
  isLoading: boolean;
  error: string | null | undefined;
}

const initialState: FoodTagOptionsState = {
  data: [],
  isLoading: false,
  error: null,
};

const fetchAllFoodTagOptions = createAsyncThunk(
  "foodTagOptions/fetchAllFoodTagOptions",
  async (_: null | undefined, { signal, rejectWithValue }) =>
    jspPlatformApi()
      .foodTag.getFoodTagAllFoodTagAllGet()
      .then((result: FoodTag[]) => {
        return result;
      })
      .catch((error) => {
        let errMsg: string;
        if (isApiError(error) && isErrorResponseBodyType(error.body)) {
          errMsg = error.body.detail;
        } else {
          errMsg = "Unknown error!";
        }
        return rejectWithValue(errMsg);
      }),
);

const updateFoodTagOptions = (
  state: FoodTagOptionsState,
  action: PayloadAction<FoodTag[] | null>,
): FoodTagOptionsState => {
  return {
    ...state,
    data: action?.payload ? action.payload : [],
  };
};

export const foodTagOptionsSlice = createSlice({
  name: "foodTagOptionsSlice",
  initialState,
  reducers: {
    setFoodTagOptions: updateFoodTagOptions,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllFoodTagOptions.pending, (state) => {
      return { ...state, isLoading: true };
    });
    builder.addCase(fetchAllFoodTagOptions.fulfilled, (state, action) => {
      return {
        ...state,
        ...updateFoodTagOptions(state, action),
        isLoading: false,
      };
    });
    builder.addCase(fetchAllFoodTagOptions.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false,
        error: action.payload as string,
      };
    });
  },
});

export const actions = {
  ...foodTagOptionsSlice.actions,
  fetchAllFoodTagOptions,
};

export default foodTagOptionsSlice.reducer;
