import {
  ApiError,
  GordonFoodServiceOrderRecord,
  StockReplenishmentCreate,
  StockReplenishmentCreateWithFoodItem,
} from "client/jspPlatformExperiment";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import { GFSOrderItemActionState } from "components/inventory/ui/replenishment/GFSOrderItemActionBox";
import jspPlatformApi from "client/portals";
import { isApiError, isErrorResponseBodyType } from "models/utils/apiUtils";

export interface restockingStateProps {
  actionItems: Record<
    string,
    {
      data: GordonFoodServiceOrderRecord;
      state: GFSOrderItemActionState;
    }
  >;
  todoReplenishmentRecords: Record<
    string,
    StockReplenishmentCreateWithFoodItem
  >;
  isLoading: boolean;
  isPushing: boolean;
  error: string | null;
}

const initialState: restockingStateProps = {
  actionItems: {},
  todoReplenishmentRecords: {},
  isLoading: false,
  isPushing: false,
  error: null,
};

const updateAnActionItem = (
  state: restockingStateProps,
  action: PayloadAction<{
    key: string;
    newData: GFSOrderItemActionState;
  } | null>,
) => {
  if (!action.payload) {
    return state;
  }
  return {
    ...state,
    actionItems: {
      ...state.actionItems,
      [action.payload.key]: {
        ...state.actionItems[action.payload.key],
        state: action.payload.newData,
      },
    },
  };
};

const updateAReplenishment = (
  state: restockingStateProps,
  action: PayloadAction<{
    key: string;
    newData: StockReplenishmentCreateWithFoodItem;
  } | null>,
) => {
  if (!action.payload) {
    return state;
  }
  return {
    ...state,
    todoReplenishmentRecords: {
      ...state.todoReplenishmentRecords,
      [action.payload.key]: action.payload.newData,
    },
  };
};

const initRestocking = (
  state: restockingStateProps,
  action: PayloadAction<{
    actionItems: GordonFoodServiceOrderRecord[];
    solvedReplenishments: StockReplenishmentCreateWithFoodItem[];
  } | null>,
) => {
  if (action.payload) {
    return {
      ...state,
      actionItems: Object.fromEntries(
        action.payload.actionItems.map((actionItem) => [
          uuidv4(),
          {
            data: actionItem,
            state: {
              chooseAction: undefined,
              actionMsg: "Waiting action to finish ...",
            },
          },
        ]),
      ),
      todoReplenishmentRecords: Object.fromEntries(
        action.payload.solvedReplenishments.map((replenishment) => [
          uuidv4(),
          replenishment,
        ]),
      ),
    };
  }
  return initialState;
};

const fetchReplenishmentsByGFSOrderCSV = createAsyncThunk(
  "restocking/fetchReplenishmentsByGFSOrderCSV",
  async (orderCSVFile: Blob | File, { signal, rejectWithValue }) => {
    return jspPlatformApi()
      .inventory.extractReplenishmentFromSupplierOrderCsvInventoryStockReplenishmentSupplierOrderCsvFilePost(
        {
          formData: {
            order_csv_file: orderCSVFile,
          },
          supplierName: "GFS",
        },
      )
      .then(([toSolve, solved]) => {
        return {
          actionItems: toSolve,
          solvedReplenishments: solved,
        };
      })
      .catch((error: ApiError) => {
        let errMsg: string;
        if (isApiError(error) && isErrorResponseBodyType(error.body)) {
          errMsg = error.body.detail;
        } else {
          errMsg = "Unknown error!";
        }
        return rejectWithValue(errMsg);
      });
  },
);

const pushReplenishments = createAsyncThunk(
  "restocking/pushReplenishments",
  async (input: StockReplenishmentCreate[], { signal, rejectWithValue }) => {
    try {
      await jspPlatformApi().inventory.replenishStockInventoryStockReplenishmentPost(
        { requestBody: input },
      );
      return initialState;
    } catch (error) {
      let errMsg: string;
      if (isApiError(error) && isErrorResponseBodyType(error.body)) {
        errMsg = error.body.detail;
      } else {
        errMsg = "Unknown error!";
      }
      return rejectWithValue(errMsg);
    }
  },
);

export const restockingSlice = createSlice({
  name: "restockingSlice",
  initialState,
  reducers: {
    updateAnActionItem,
    updateAReplenishment,
    initRestocking,
  },
  extraReducers: (builder) => {
    builder.addCase(pushReplenishments.pending, (state) => {
      return { ...state, isPushing: true };
    });
    builder.addCase(pushReplenishments.fulfilled, () => {
      return initialState;
    });
    builder.addCase(pushReplenishments.rejected, (state, action) => {
      return { ...state, isPushing: false, error: action.payload as string };
    });
    builder.addCase(fetchReplenishmentsByGFSOrderCSV.pending, (state) => {
      return { ...state, isLoading: true };
    });
    builder.addCase(
      fetchReplenishmentsByGFSOrderCSV.fulfilled,
      (state, action) => {
        return {
          ...state,
          ...initRestocking(state, action),
          isLoading: false,
        };
      },
    );
    builder.addCase(
      fetchReplenishmentsByGFSOrderCSV.rejected,
      (state, action) => {
        return { ...state, isLoading: false, error: action.payload as string };
      },
    );
  },
});

export const actions = {
  ...restockingSlice.actions,
  fetchReplenishmentsByGFSOrderCSV,
  pushReplenishments,
};

export default restockingSlice.reducer;
