import { FC, useCallback, useState } from "react";
import { FormControlLabel } from "@mui/material";
import styled from "styled-components";
import YesNoSwitch from "components/common/ui/YesNoSwitch";
import { FoodTag } from "client/jspPlatformExperiment";

interface FoodTagSwitchProps {
  foodTag: FoodTag;
  defaultState?: boolean;
  onChange: (changeTag: FoodTag) => void;
}

const StyledFormControlLabel = styled(FormControlLabel)`
  padding: 0 12px 0 6px;
`;

const FoodTagSwitch: FC<FoodTagSwitchProps> = ({
  foodTag,
  onChange,
  defaultState = false,
}) => {
  const [isYes, setIsYes] = useState<boolean>(defaultState);

  const toggleOnOff = useCallback(() => {
    onChange(foodTag);
    setIsYes(!isYes);
  }, [foodTag, isYes, onChange]);

  return (
    <StyledFormControlLabel
      control={<YesNoSwitch checked={isYes} onChange={toggleOnOff} />}
      label={foodTag.tag_name}
    />
  );
};

export default FoodTagSwitch;
