import { FC, useEffect, useState } from "react";
import PageHeader from "components/common/ui/PageHeader";
import { Container } from "@mui/material";
import { useAppDispatch, useAppSelector } from "hooks/useReduxStore";
import { useParams } from "react-router-dom";
import MenuEditForms from "components/menu/ui/MenuEditForms";
import jspPlatformApi from "client/portals";
import { setMenuDetail } from "store/slices/menu/menuDetailSlice";
import { actions as foodItemOptionsActions } from "store/slices/food/foodItemOptionsSlice";
import foodItemOptionsSelector from "store/selectors/food/foodItemOptionsSelector";

interface MenuEditPageProps {}

const MenuEditPage: FC<MenuEditPageProps> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const foodItemOptions = useAppSelector(
    foodItemOptionsSelector.foodDishOptions,
  );
  const { menuId } = useParams();

  /**
   * Use effect to load the menu to edit
   */
  useEffect(() => {
    const navToEditMenuId: number | undefined = menuId
      ? Number(menuId)
      : undefined;
    if (navToEditMenuId) {
      setIsLoading(true);
      jspPlatformApi()
        .menu.getMenuMenuMealMenuIdGet({ menuId: navToEditMenuId })
        .then((menu) => {
          dispatch(setMenuDetail(menu));
        })
        .catch(() => {
          dispatch(setMenuDetail(null));
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [dispatch, menuId]);

  /**
   * Clear menu template details on unmount
   */
  useEffect(() => {
    return () => {
      dispatch(setMenuDetail(null));
    };
  }, [dispatch]);

  /**
   * Make sure FoodItemOptions is the latest
   */
  useEffect(() => {
    dispatch(foodItemOptionsActions.fetchAllFoodItemOptions());
  }, [dispatch]);

  return (
    <Container>
      <PageHeader>Menu Details</PageHeader>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <MenuEditForms foodItemOptions={foodItemOptions} />
      )}
    </Container>
  );
};

export default MenuEditPage;
