import { Container, Grid } from "@mui/material";
import BottomSpacedPageHeader from "components/common/ui/BottomSpacedPageHeader";
import CommonButton from "components/common/ui/CommonButton";
import EditSection from "components/common/ui/EditSection";
import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useState,
} from "react";
import GFSOrderItemActionBox from "components/inventory/ui/replenishment/GFSOrderItemActionBox";
import ColorCircularProgress from "components/common/ui/ColorCircularProgress";
import styled, { css } from "styled-components";
import { usePromptSnackBar } from "components/common/ui/PromptSnackBarProvider";
import AdaptiveRowHeader from "components/common/ui/AdaptiveRowHeader";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useAppDispatch, useAppSelector } from "hooks/useReduxStore";
import { actions } from "store/slices/inventory/restockingSlice";
import restockingSelector from "store/selectors/inventory/restockingSelector";
import { useNavigate } from "react-router-dom";
import Routes from "models/routes";
import { actions as foodItemOptionsActions } from "store/slices/food/foodItemOptionsSlice";
import foodItemOptionsSelector from "store/selectors/food/foodItemOptionsSelector";
import foodTagOptionsSelector from "store/selectors/food/foodTagOptionsSelector";
import { actions as foodTagOptionsActions } from "store/slices/food/foodTagOptionsSlice";
import ReplenishmentActionBox from "./ReplenishmentActionBox";

const ProgressPromptField = styled(Typography)`
  font-size: 1rem;
  font-weight: 500;
  padding: 0 0 0 2rem;
  color: rgb(120, 203, 255);
`;

enum fileUploadingState {
  notUploaded = "notUploaded",
  uploaded = "uploaded",
}

interface ReplenishBySupplierOrderCSVProps {}

const ReplenishBySupplierOrderCSVPage: FC<
  ReplenishBySupplierOrderCSVProps
> = () => {
  const dispatch = useAppDispatch();
  const promptSnackBar = usePromptSnackBar();
  const navigate = useNavigate();
  const [file, setFile] = useState<File | null>(null);
  const [fileState, setFileState] = useState<fileUploadingState>(
    fileUploadingState.notUploaded,
  );
  const toSolvedOrderItems = useAppSelector(
    restockingSelector.restockingActionItems,
  );
  const solvedReplenishments = useAppSelector(
    restockingSelector.restockingReplenishments,
  );
  const isPushingReplenishments = useAppSelector(
    restockingSelector.restockingIsPushing,
  );
  const isFileUploading = useAppSelector(
    restockingSelector.restockingIsLoading,
  );

  const ingredientOptions = useAppSelector(
    foodItemOptionsSelector.foodIngredientOptions,
  );
  const foodTagOptions = useAppSelector(foodTagOptionsSelector.foodTagOptions);

  /**
   * Use effect to load the food tag option when first time loading the page
   */
  useEffect(() => {
    if (foodTagOptions.length === 0) {
      dispatch(foodTagOptionsActions.fetchAllFoodTagOptions());
    }
  }, [dispatch, foodTagOptions.length]);

  /**
   * Make sure FoodItemOptions is the latest
   */
  useEffect(() => {
    dispatch(foodItemOptionsActions.fetchAllFoodItemOptions());
  }, [dispatch]);

  const handleFileChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
      setFileState(fileUploadingState.notUploaded);
    }
  }, []);

  const handleFileUploadClick = useCallback(() => {
    if (file) {
      dispatch(actions.initRestocking(null));
      dispatch(actions.fetchReplenishmentsByGFSOrderCSV(file))
        .unwrap() // Unwrap the result to handle success or failure
        .then(() => {
          setFileState(fileUploadingState.uploaded);
        })
        .catch((err) => {
          promptSnackBar.setPromptProps({
            isOpen: true,
            message: `File uploading failed: ${err}`,
            severity: "error",
          });
          setFileState(fileUploadingState.notUploaded);
        });
    }
  }, [dispatch, file, promptSnackBar]);

  const handleRestockClick = useCallback(() => {
    dispatch(
      actions.pushReplenishments(
        Object.values(solvedReplenishments).map(
          (replenishment) => replenishment,
        ),
      ),
    )
      .unwrap() // Unwrap the result to handle success or failure
      .then(() => {
        promptSnackBar.setPromptProps({
          isOpen: true,
          message: `Restocking success.`,
          severity: "success",
          autoHeightDuration: 5000,
        });
        dispatch(actions.initRestocking(null));
        navigate(Routes.INVENTORY, {
          replace: false,
        });
      })
      .catch((err) => {
        promptSnackBar.setPromptProps({
          isOpen: true,
          message: `Restocking failed. ${err}`,
          severity: "error",
        });
      });
  }, [dispatch, navigate, promptSnackBar, solvedReplenishments]);

  return (
    <Container>
      <BottomSpacedPageHeader>
        Restock via Supplier Order File
      </BottomSpacedPageHeader>
      <EditSection header="Select CSV file">
        <input id="order-csv-file" type="file" onChange={handleFileChange} />
        {file && (
          <section>
            File details:
            <ul>
              <li>Name: {file.name}</li>
              <li>Type: {file.type}</li>
            </ul>
          </section>
        )}
        {file &&
          fileState === fileUploadingState.notUploaded &&
          !isFileUploading && (
            <CommonButton
              onClick={handleFileUploadClick}
              text="Upload a file"
            />
          )}
      </EditSection>
      {isFileUploading ? (
        <Grid container columns={2}>
          <AdaptiveRowHeader switchWidthInPixel={1}>
            <Box sx={{ display: "flex" }}>
              <ColorCircularProgress
                color={css`rgb(120, 203, 255)`}
                thickness={20}
              />
            </Box>
            <ProgressPromptField>Analysing ...</ProgressPromptField>
          </AdaptiveRowHeader>
        </Grid>
      ) : null}
      <EditSection header="Need action items">
        {Object.entries(toSolvedOrderItems).map(
          ([actionItemKey, orderItem]) => (
            <GFSOrderItemActionBox
              key={actionItemKey}
              displayKey={actionItemKey}
              data={orderItem.data}
              state={orderItem.state}
              foodTagOptions={foodTagOptions}
              ingredientOptions={ingredientOptions}
            />
          ),
        )}
      </EditSection>
      <EditSection header="Resolved replenishments">
        {Object.entries(solvedReplenishments).map(
          ([displayKey, replenishment]) => (
            <ReplenishmentActionBox
              key={displayKey}
              displayKey={displayKey}
              data={replenishment}
            />
          ),
        )}
      </EditSection>
      <EditSection>
        <AdaptiveRowHeader switchWidthInPixel={1}>
          {isPushingReplenishments ? (
            <>
              <Box sx={{ display: "flex" }}>
                <ColorCircularProgress
                  color={css`rgb(120, 203, 255)`}
                  thickness={20}
                />
              </Box>
              <ProgressPromptField>
                Pushing restocking records ...
              </ProgressPromptField>
            </>
          ) : (
            <CommonButton
              text="Restock"
              positive="true"
              onClick={handleRestockClick}
            />
          )}
        </AdaptiveRowHeader>
      </EditSection>
      <EditSection />
    </Container>
  );
};

export default ReplenishBySupplierOrderCSVPage;
