import { RootState } from "store/index";
import { isFoodIngredient, isFoodRecipe } from "models/food/item/foodItem";
import { FoodRecipeKey } from "client/jspPlatformExperiment";

const foodItemOptions = (state: RootState) => {
  return Object.values(state.foodItemOptions.data);
};

const foodIngredientOptions = (state: RootState) => {
  return Object.values(state.foodItemOptions.data).filter((value) =>
    isFoodIngredient(value),
  );
};

const foodRecipeOptions = (state: RootState) => {
  return Object.values(state.foodItemOptions.data)
    .filter((value) => isFoodRecipe(value))
    .map((value): FoodRecipeKey => {
      const recipe = value.food_recipes![0];
      return {
        id: recipe.id,
        name: recipe.name,
        food_id: value.id,
        food_name: value.food_name,
      };
    });
};

const foodDishOptions = (state: RootState) => {
  return Object.values(state.foodItemOptions.data).filter(
    (value) => !!value.portion_size && value.portion_size > 0,
  );
};

const foodItemOptionsUpdatedAt = (state: RootState) =>
  state.foodItemOptions.updatedAt;

export default {
  foodItemOptions,
  foodIngredientOptions,
  foodRecipeOptions,
  foodDishOptions,
  foodItemOptionsUpdatedAt,
};
