import React, {
  ChangeEvent,
  Dispatch,
  FC,
  KeyboardEvent,
  SetStateAction,
  useCallback,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import Routes from "models/routes";
import { GridToolbarContainer } from "@mui/x-data-grid";
import CommonButton from "components/common/ui/CommonButton";
import AddIcon from "@mui/icons-material/Add";
import { Grid, InputAdornment, Tooltip } from "@mui/material";
import RecipeStepGridItemBox from "components/food/ui/recipe/RecipeStepGridItemBox";
import SpacedTextInput from "components/common/ui/SpacedTextInput";
import CircleIconButton from "components/common/ui/CircleIconButton";
import ClearIcon from "@mui/icons-material/Clear";
import Box from "@mui/material/Box";
import SoftSquareIconButton from "components/common/ui/SoftSquareIconButton";
import SearchIcon from "@mui/icons-material/Search";

interface RecipeListTableToolbarProps {
  setKeywords: Dispatch<SetStateAction<string | undefined>>;
}

const RecipeListTableToolbar: FC<RecipeListTableToolbarProps> = ({
  setKeywords,
}) => {
  const navigate = useNavigate();
  const [displayKeywords, setDisplayKeywords] = useState<string | undefined>();

  /**
   * Handle the keywords change
   * @param event
   */
  const handleKeywordsChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const newValue = event.target.value ?? undefined;
    setDisplayKeywords(newValue);
  };

  /**
   * Handle click on create button
   */
  const handleCreateClick = (): void => {
    navigate(Routes.FOOD_RECIPE_CREATE, {
      replace: false,
    });
  };

  /**
   * Handle click on search button
   */
  const handleSearchClick = useCallback(
    (searchingKeywords: string | undefined) => (): void => {
      setKeywords(searchingKeywords);
    },
    [setKeywords],
  );

  /**
   * Handle click on clear icon in searching bar
   */
  const handleClearClick = useCallback(() => {
    handleSearchClick(undefined)();
    setDisplayKeywords(undefined);
  }, [handleSearchClick]);

  /**
   * Handle keypress while focus on keyword field
   */
  const handleKeypress = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      if (event.key === "Enter") {
        handleSearchClick(displayKeywords)();
      } else if (event.key === "Escape") {
        handleClearClick();
      }
    },
    [displayKeywords, handleClearClick, handleSearchClick],
  );

  return (
    <GridToolbarContainer>
      <Grid container spacing={1} columns={24}>
        <Grid item xs={24} sm={24} md={7} lg={8}>
          <CommonButton
            text="Create"
            positive="true"
            startIcon={<AddIcon />}
            onClick={handleCreateClick}
          />
        </Grid>
        <Grid item xs={24} sm={24} md={17} lg={16}>
          <RecipeStepGridItemBox>
            <SpacedTextInput
              size="small"
              label="Keywords"
              InputProps={{
                endAdornment: displayKeywords ? (
                  <InputAdornment position="end">
                    <CircleIconButton
                      natural="true"
                      onClick={handleClearClick}
                      sizepx={24}
                    >
                      <ClearIcon />
                    </CircleIconButton>
                  </InputAdornment>
                ) : null,
              }}
              onChange={handleKeywordsChange}
              onKeyDown={handleKeypress}
              value={displayKeywords ?? ""}
            />
            <Box>
              <Tooltip title="Search">
                <Box padding="3px 1rem 3px 0.5rem">
                  <SoftSquareIconButton
                    positive="false"
                    onClick={handleSearchClick(displayKeywords)}
                    sizepx={34}
                  >
                    <SearchIcon />
                  </SoftSquareIconButton>
                </Box>
              </Tooltip>
            </Box>
          </RecipeStepGridItemBox>
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );
};

export default RecipeListTableToolbar;
