import { FoodItem } from "client/jspPlatformExperiment";

export const isFoodItem = (o: unknown): o is FoodItem => {
  return !!o && typeof o === "object" && "updated_by_user_id" in o;
};

export const isFoodItemArray = (o: Array<unknown>): o is FoodItem[] => {
  return !!o && typeof o === "object" && o.every(isFoodItem);
};

export const isFoodIngredient = (o: unknown): o is FoodItem => {
  return (
    !!o &&
    typeof o === "object" &&
    "updated_by_user_id" in o &&
    (o as FoodItem).food_recipes?.length === 0 &&
    (o as FoodItem).food_suppliers?.length !== 0
  );
};

export const isFoodRecipe = (o: unknown): o is FoodItem => {
  return (
    !!o &&
    typeof o === "object" &&
    "updated_by_user_id" in o &&
    (o as FoodItem).food_recipes?.length !== 0 &&
    (o as FoodItem).food_suppliers?.length === 0
  );
};
