import React, {
  Dispatch,
  FC,
  SetStateAction,
  SyntheticEvent,
  useCallback,
} from "react";
import { AlertColor } from "@mui/material/Alert/Alert";
import { Alert, Snackbar } from "@mui/material";

export type PromptProps = {
  isOpen: boolean;
  message: string;
  severity: AlertColor;
  autoHeightDuration?: number;
};

export interface PromptSnackBarProps {
  promptProps: PromptProps;
  setPromptProps: Dispatch<SetStateAction<PromptProps>>;
  onPromptClose?: () => void;
}

const PromptSnackBar: FC<PromptSnackBarProps> = ({
  promptProps,
  setPromptProps,
  onPromptClose,
}) => {
  /**
   * Handle prompt message close
   */
  const handlePromptClose = useCallback(
    (event?: SyntheticEvent | Event, reason?: string): void => {
      if (reason === "clickaway") {
        return;
      }

      onPromptClose?.();
      setPromptProps({ ...promptProps, isOpen: false });
    },
    [onPromptClose, promptProps, setPromptProps],
  );

  return (
    <Snackbar
      // anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={promptProps.isOpen}
      autoHideDuration={promptProps.autoHeightDuration}
      onClose={handlePromptClose}
    >
      <Alert
        onClose={handlePromptClose}
        severity={promptProps.severity}
        variant="filled"
        sx={{ width: "100%" }}
      >
        {promptProps.message}
      </Alert>
    </Snackbar>
  );
};

export default PromptSnackBar;
