import React, { FC, useCallback, useEffect } from "react";
import EditSection from "components/common/ui/EditSection";
import { Stack } from "@mui/material";
import PillButton from "components/common/ui/PillButton";
import Box from "@mui/material/Box";
import PortionOrderInputFormRow from "components/inventory/ui/purchasing/portionOrders/PortionOrderInputFormRow";
import { useAppDispatch, useAppSelector } from "hooks/useReduxStore";
import dishPortionOrdersSelector from "store/selectors/order/dishPortionOrdersSelector";
import { actions } from "store/slices/order/dishPortionOrdersSlice";
import { actions as foodItemOptionsActions } from "store/slices/food/foodItemOptionsSlice";
import foodItemOptionsSelector from "store/selectors/food/foodItemOptionsSelector";

interface PortionOrderInputFormProps {}

const PortionOrderInputForm: FC<PortionOrderInputFormProps> = () => {
  const foodItemOptions = useAppSelector(
    foodItemOptionsSelector.foodDishOptions,
  );
  const portionOrders = useAppSelector(
    dishPortionOrdersSelector.allDishPortionOrders,
  );
  const dispatch = useAppDispatch();

  /**
   * Make sure FoodItemOptions is the latest
   */
  useEffect(() => {
    dispatch(foodItemOptionsActions.fetchAllFoodItemOptions());
  }, [dispatch]);

  /**
   * Add a new row to dish portion orders
   */
  const handleAppendEntity = useCallback(() => {
    dispatch(actions.appendDishPortionOrder());
  }, [dispatch]);

  return (
    <EditSection header="Required portion orders">
      <Stack spacing={1}>
        {Object.entries(portionOrders).map(([key]) => (
          <PortionOrderInputFormRow
            key={key}
            foodItemOptions={foodItemOptions}
            displayUUID={key}
          />
        ))}
        <Box sx={{ alignSelf: "center" }}>
          <PillButton text="Add portion order" onClick={handleAppendEntity} />
        </Box>
      </Stack>
    </EditSection>
  );
};

export default PortionOrderInputForm;
