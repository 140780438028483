import { RootState } from "store/index";

const restockingActionItems = (state: RootState) =>
  state.restocking.actionItems;

const restockingReplenishments = (state: RootState) =>
  state.restocking.todoReplenishmentRecords;

const restockingIsPushing = (state: RootState) => state.restocking.isPushing;

const restockingIsLoading = (state: RootState) => state.restocking.isLoading;

const restockingError = (state: RootState) => state.restocking.error;

export default {
  restockingActionItems,
  restockingReplenishments,
  restockingIsPushing,
  restockingIsLoading,
  restockingError,
};
