import List from "@mui/material/List";
import { Dispatch, FC, SetStateAction, useCallback } from "react";
import FoodTagSwitch from "components/food/ui/TagSwitch";
import { FoodTag, FoodTagCategory } from "client/jspPlatformExperiment";
import styled from "styled-components";
import Typography from "@mui/material/Typography";

const StyledTagCategoryHeader = styled(Typography)`
  font-size: 1rem;
  font-weight: 200;
  padding: 8px 0 2px 0;
  color: #ccc;
`;

interface FoodItemTagEditSectionProps {
  foodTagOptions: FoodTag[];
  foodItemTags: FoodTag[];
  setFoodItemTags: Dispatch<SetStateAction<FoodTag[]>>;
}

const FoodItemTagEditSection: FC<FoodItemTagEditSectionProps> = ({
  foodTagOptions,
  foodItemTags,
  setFoodItemTags,
}) => {
  /**
   * Add/remove tag from food item tags
   */
  const handleTagChange = useCallback(
    (changeTag: FoodTag) => {
      const changeTagIdx: number = foodItemTags.findIndex(
        (foodItemTag) => foodItemTag.id === changeTag.id,
      );
      if (changeTagIdx !== -1) {
        setFoodItemTags(
          foodItemTags.filter((foodItemTag) => foodItemTag.id !== changeTag.id),
        );
      } else {
        setFoodItemTags([...foodItemTags.slice(), changeTag]);
      }
    },
    [foodItemTags, setFoodItemTags],
  );

  /**
   * Get the default state by checking if the tag is in the food tags
   */
  const getDefaultState = useCallback(
    (checkTag: FoodTag) => {
      return (
        foodItemTags.find((foodItemTag) => foodItemTag.id === checkTag.id) !==
        undefined
      );
    },
    [foodItemTags],
  );

  return (
    <>
      <List>
        <StyledTagCategoryHeader>Allergies</StyledTagCategoryHeader>
        {foodTagOptions
          .filter((foodTagOption) =>
            foodTagOption.tag_categories.includes(FoodTagCategory.ALLERGEN),
          )
          .map((foodTagOption) => {
            return (
              <FoodTagSwitch
                key={`foodTag${foodTagOption.id}`}
                foodTag={foodTagOption}
                onChange={handleTagChange}
                defaultState={getDefaultState(foodTagOption)}
              />
            );
          })}
      </List>
      <List>
        <StyledTagCategoryHeader>Suitable Diet Types</StyledTagCategoryHeader>
        {foodTagOptions
          .filter(
            (foodTagOption) =>
              foodTagOption.tag_categories.includes(
                FoodTagCategory.DIET_TYPE,
              ) &&
              !foodTagOption.tag_categories.includes(FoodTagCategory.ALLERGEN),
          )
          .map((foodTagOption) => {
            return (
              <FoodTagSwitch
                key={`foodTag${foodTagOption.id}`}
                foodTag={foodTagOption}
                onChange={handleTagChange}
                defaultState={getDefaultState(foodTagOption)}
              />
            );
          })}
      </List>
      <List>
        <StyledTagCategoryHeader>
          Suitable Food Textures
        </StyledTagCategoryHeader>
        {foodTagOptions
          .filter(
            (foodTagOption) =>
              foodTagOption.tag_categories.includes(
                FoodTagCategory.FOOD_TEXTURE,
              ) &&
              !(
                foodTagOption.tag_categories.includes(
                  FoodTagCategory.ALLERGEN,
                ) ||
                foodTagOption.tag_categories.includes(FoodTagCategory.DIET_TYPE)
              ),
          )
          .map((foodTagOption) => {
            return (
              <FoodTagSwitch
                key={`foodTag${foodTagOption.id}`}
                foodTag={foodTagOption}
                onChange={handleTagChange}
                defaultState={getDefaultState(foodTagOption)}
              />
            );
          })}
      </List>
      <List>
        <StyledTagCategoryHeader>
          Suitable Liquid Thickness
        </StyledTagCategoryHeader>
        {foodTagOptions
          .filter(
            (foodTagOption) =>
              foodTagOption.tag_categories.includes(
                FoodTagCategory.LIQUID_THICKNESS,
              ) &&
              !(
                foodTagOption.tag_categories.includes(
                  FoodTagCategory.ALLERGEN,
                ) ||
                foodTagOption.tag_categories.includes(
                  FoodTagCategory.DIET_TYPE,
                ) ||
                foodTagOption.tag_categories.includes(
                  FoodTagCategory.FOOD_TEXTURE,
                )
              ),
          )
          .map((foodTagOption) => {
            return (
              <FoodTagSwitch
                key={`foodTag${foodTagOption.id}`}
                foodTag={foodTagOption}
                onChange={handleTagChange}
                defaultState={getDefaultState(foodTagOption)}
              />
            );
          })}
      </List>
      <List>
        <StyledTagCategoryHeader>Contains</StyledTagCategoryHeader>
        {foodTagOptions
          .filter(
            (foodTagOption) =>
              foodTagOption.tag_categories.includes(
                FoodTagCategory.FOOD_CATEGORY,
              ) &&
              !(
                foodTagOption.tag_categories.includes(
                  FoodTagCategory.ALLERGEN,
                ) ||
                foodTagOption.tag_categories.includes(
                  FoodTagCategory.DIET_TYPE,
                ) ||
                foodTagOption.tag_categories.includes(
                  FoodTagCategory.FOOD_TEXTURE,
                ) ||
                foodTagOption.tag_categories.includes(
                  FoodTagCategory.LIQUID_THICKNESS,
                )
              ),
          )
          .map((foodTagOption) => {
            return (
              <FoodTagSwitch
                key={`foodTag${foodTagOption.id}`}
                foodTag={foodTagOption}
                onChange={handleTagChange}
                defaultState={getDefaultState(foodTagOption)}
              />
            );
          })}
      </List>
    </>
  );
};

export default FoodItemTagEditSection;
