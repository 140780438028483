import styled, { css } from "styled-components";
import { CircularProgress } from "@mui/material";

interface ColorCircularProgressProps {
  color?: ReturnType<typeof css>;
}

const ColoredCircularProgress = styled(CircularProgress).withConfig({
  shouldForwardProp: (prop) => !["color"].includes(prop),
})<ColorCircularProgressProps>`
  ${({ color }) => {
    let colorOut = css`"none"`;
    if (color) {
      colorOut = color;
    }

    return css`
      color: ${colorOut};
    `;
  }}
`;

export default ColoredCircularProgress;
