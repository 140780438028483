import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import EditSection from "components/common/ui/EditSection";
import CommonButton from "components/common/ui/CommonButton";
import FoodItemBasicInfoEditSection from "components/food/ui/FoodItemBasicInfoEditSection";
import FoodItemNutritionEditSection from "components/food/ui/FoodItemNutritionEditSection";
import FoodItemTagEditSection from "components/food/ui/FoodItemTagEditSection";
import FoodSupplierEditSection from "components/food/ui/FoodSupplierEditSection";
import {
  ApiError,
  FoodItem,
  FoodItemCreate,
  FoodItemNutrition,
  FoodItemNutritionCreate,
  FoodItemNutritionUpdate,
  FoodItemSupplierInfo,
  FoodItemSupplierInfoCreate,
  FoodItemSupplierInfoUpdate,
  FoodItemUpdate,
  FoodTag,
  IngredientState,
  StorageTemperature,
} from "client/jspPlatformExperiment";
import { useAppSelector } from "hooks/useReduxStore";
import jspPlatformApi from "client/portals";
import foodItemDetailSelector from "store/selectors/foodItem/foodItemDetailSelector";
import { usePromptSnackBar } from "components/common/ui/PromptSnackBarProvider";
import { isErrorResponseBodyType } from "models/utils/apiUtils";
import dayjs from "dayjs";

interface IngredientEditFormsProps {
  foodTagOptions: FoodTag[];
  saveButtonCallback?: VoidFunction;
}

const IngredientEditForms: FC<IngredientEditFormsProps> = ({
  foodTagOptions,
  saveButtonCallback,
}) => {
  const promptSnackBar = usePromptSnackBar();
  const navigate = useNavigate();
  const editingFoodItemData = useAppSelector(
    foodItemDetailSelector.foodItemBasic,
  );
  const editingFoodTags = useAppSelector(foodItemDetailSelector.foodTags);
  const editingFoodSuppliers = useAppSelector(
    foodItemDetailSelector.foodSuppliers,
  );
  const editingFoodNutritionRecords = useAppSelector(
    foodItemDetailSelector.foodNutritionRecords,
  );
  const { foodId } = useParams();

  const [foodItem, setFoodItem] = useState<FoodItem>({
    food_name: "",
    state: IngredientState.NONE,
    category: "other",
    storage_temp: StorageTemperature.FRIDGE,
    shelf_life:
      editingFoodItemData?.shelf_life ??
      dayjs.duration({ minutes: 1440 }).toISOString(),
    dispense_category: "",
    food_nutrition_records: [],
    id: editingFoodItemData?.id ? editingFoodItemData?.id : 0,
  });

  const [nutritionFacts, setNutritionFacts] = useState<FoodItemNutrition>({
    data_source: "",
    serving_size: 100,
    food_id: editingFoodItemData?.id ?? 0,
  });

  const [foodItemTags, setFoodItemTags] = useState<FoodTag[]>(
    editingFoodTags ?? [],
  );
  const [supplierInfo, setSupplierInfo] = useState<FoodItemSupplierInfo>({
    id: 0,
    supplier_id: "",
    product_code: "",
    product_name: "",
    food_id: editingFoodItemData?.id ? editingFoodItemData.id : 0,
    supplier_unit_size: 0,
    supplier_unit_size_uom: "",
  });

  /**
   * Use effect to load the editing ingredient data when first time loading the page
   */
  useEffect(() => {
    if (editingFoodItemData) {
      setFoodItem(editingFoodItemData);
      if ((editingFoodNutritionRecords ?? []).length > 0) {
        setNutritionFacts((editingFoodNutritionRecords ?? [])[0]);
      }
      if ((editingFoodSuppliers ?? []).length > 0) {
        setSupplierInfo((editingFoodSuppliers ?? [])[0]);
      }
      setFoodItemTags(editingFoodTags ?? []);
    }
  }, [
    editingFoodItemData,
    setFoodItem,
    setNutritionFacts,
    setSupplierInfo,
    setFoodItemTags,
    editingFoodNutritionRecords,
    editingFoodSuppliers,
    editingFoodTags,
  ]);

  /**
   * Save food item (ingredient) data to the back-end database
   */
  const handleSaveClick = useCallback(() => {
    const editingFoodId: number | undefined = foodId
      ? Number(foodId)
      : undefined;
    if (editingFoodItemData && editingFoodId) {
      // Update
      jspPlatformApi()
        .ingredient.updateIngredientFoodIngFoodIdPatch({
          foodId: editingFoodItemData.id,
          requestBody: {
            ingredient: foodItem as FoodItemUpdate,
            supplier_info: supplierInfo as FoodItemSupplierInfoUpdate,
            nutrition_facts: nutritionFacts as FoodItemNutritionUpdate,
            food_tag_ids: foodItemTags.map((foodItemTag) => foodItemTag.id),
          },
        })
        .then(() => {
          promptSnackBar.setPromptProps({
            isOpen: true,
            message: "Ingredient updating succeed.",
            severity: "success",
            autoHeightDuration: 5000,
          });
          if (saveButtonCallback) {
            saveButtonCallback();
          } else {
            navigate(-1);
          }
        })
        .catch((reason: ApiError) => {
          let message = `[${reason.status}] ${reason.statusText}`;
          if (isErrorResponseBodyType(reason.body)) {
            message = reason.body.detail;
            if (reason.status === 422) {
              message = JSON.stringify(reason.body.detail);
            }
          }
          promptSnackBar.setPromptProps({
            isOpen: true,
            message: `Ingredient updating failed: ${message}`,
            severity: "error",
          });
        });
    } else {
      // Create
      jspPlatformApi()
        .ingredient.createIngredientFoodIngPost({
          requestBody: {
            ingredient: foodItem as FoodItemCreate,
            supplier_info: supplierInfo as FoodItemSupplierInfoCreate,
            nutrition_facts: nutritionFacts as FoodItemNutritionCreate,
            food_tag_ids: foodItemTags.map((foodItemTag) => foodItemTag.id),
          },
        })
        .then(() => {
          promptSnackBar.setPromptProps({
            isOpen: true,
            message: "Ingredient creation succeed.",
            severity: "success",
            autoHeightDuration: 5000,
          });
          if (saveButtonCallback) {
            saveButtonCallback();
          } else {
            navigate(-1);
          }
        })
        .catch((reason: ApiError) => {
          let message = `[${reason.status}] ${reason.statusText}`;
          if (isErrorResponseBodyType(reason.body)) {
            message = reason.body.detail;
            if (reason.status === 422) {
              message = JSON.stringify(reason.body.detail);
            }
          }
          promptSnackBar.setPromptProps({
            isOpen: true,
            message: `Ingredient creation failed: ${message}`,
            severity: "error",
          });
        });
    }
  }, [
    foodId,
    editingFoodItemData,
    foodItem,
    supplierInfo,
    nutritionFacts,
    foodItemTags,
    promptSnackBar,
    saveButtonCallback,
    navigate,
  ]);

  return (
    <>
      <EditSection>
        <CommonButton text="Save" positive="true" onClick={handleSaveClick} />
      </EditSection>
      <EditSection
        header={
          foodId
            ? `Basic Information (Food ID: ${foodId})`
            : "Basic Information"
        }
      >
        <FoodItemBasicInfoEditSection
          foodItem={foodItem}
          setFoodItem={setFoodItem}
        />
      </EditSection>
      <EditSection header="Nutrition Facts">
        <FoodItemNutritionEditSection
          nutritionFacts={nutritionFacts}
          setNutritionFacts={setNutritionFacts}
        />
      </EditSection>
      <EditSection header="Tags">
        <FoodItemTagEditSection
          foodTagOptions={foodTagOptions}
          foodItemTags={foodItemTags}
          setFoodItemTags={setFoodItemTags}
        />
      </EditSection>
      <EditSection header="Supplier Information">
        <FoodSupplierEditSection
          supplierInfo={supplierInfo}
          setSupplierInfo={setSupplierInfo}
        />
      </EditSection>
      <EditSection>
        <CommonButton text="Save" positive="true" onClick={handleSaveClick} />
      </EditSection>
    </>
  );
};

export default IngredientEditForms;
