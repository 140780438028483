import { FoodItemKey } from "client/jspPlatformExperiment";
import React, {
  ChangeEvent,
  FC,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Grid, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import CircleIconButton from "components/common/ui/CircleIconButton";
import CloseIcon from "@mui/icons-material/Close";
import SpacedTextInput, {
  SpacedAutocompleteInput,
} from "components/common/ui/SpacedTextInput";
import { useAppDispatch, useAppSelector } from "hooks/useReduxStore";
import { actions } from "store/slices/order/dishPortionOrdersSlice";
import dishPortionOrdersSelector from "store/selectors/order/dishPortionOrdersSelector";
import AdaptiveRowHeader from "components/common/ui/AdaptiveRowHeader";
import { getFoodItemOptionMaps } from "utils/food/foodItemOptionDisplayUtils";

interface PortionOrderInputFormRowProps {
  foodItemOptions: FoodItemKey[];
  displayUUID: string;
}

const PortionOrderInputFormRow: FC<PortionOrderInputFormRowProps> = ({
  foodItemOptions,
  displayUUID,
}) => {
  const dispatch = useAppDispatch();
  const dishPortionOrder = useAppSelector(
    dishPortionOrdersSelector.selectADishPortionOrders(displayUUID),
  );

  const [foodItemOptionToIdMap, setFoodItemOptionToIdMap] = useState<
    Record<string, number>
  >({ "": 0 });
  const [idToFoodItemOptionMap, setIdToFoodItemOptionMap] = useState<
    Map<number, string>
  >(new Map([[0, ""]]));
  const [displayAmount, setDisplayAmount] = useState<string | number | null>(
    dishPortionOrder?.amount,
  );

  /**
   * Use effect to sync the food item option names
   */
  useEffect(() => {
    const { foodItemOptionNamesToIdsMap, idsToFoodItemOptionNamesMap } =
      getFoodItemOptionMaps(foodItemOptions);
    setFoodItemOptionToIdMap(foodItemOptionNamesToIdsMap);
    setIdToFoodItemOptionMap(idsToFoodItemOptionNamesMap);
  }, [foodItemOptions]);

  /**
   * Update current row from the dish portion orders
   */
  const handleUpdateEntity = useCallback(
    (entityUUID: string, field: string, value: number | null) => {
      dispatch(
        actions.setDishPortionOrders({
          entityUUID,
          dishPortionOrder: { [field]: value },
        }),
      );
    },
    [dispatch],
  );

  /**
   * Remove current row from the dish portion orders
   */
  const handleRemoveEntity = useCallback(
    (entityUUID: string) => () => {
      dispatch(actions.deleteDishPortionOrder({ entityUUID }));
    },
    [dispatch],
  );

  /**
   * Handle food selection field changes
   */
  const handleFoodSelectionChange = useCallback(
    (_: SyntheticEvent, newValue: string | null) => {
      let newFoodId: number | null = null;
      if (newValue) {
        newFoodId = foodItemOptionToIdMap[newValue];
      }
      handleUpdateEntity(displayUUID, "food_id", newFoodId);
    },
    [displayUUID, foodItemOptionToIdMap, handleUpdateEntity],
  );

  /**
   * Handle portion amount change
   */
  const handleAmountChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const valueInput: number | string = event.target.value;
      setDisplayAmount(valueInput);
      handleUpdateEntity(
        displayUUID,
        "amount",
        Number(valueInput) ? Number(valueInput) : 0,
      );
    },
    [displayUUID, handleUpdateEntity],
  );

  return (
    <Box display="flex" justifyContent="flex-end">
      <AdaptiveRowHeader switchWidthInPixel={1}>
        <Tooltip title="Remove this row">
          <CircleIconButton
            danger="true"
            positive="true"
            sizepx={30}
            onClick={handleRemoveEntity(displayUUID)}
          >
            <CloseIcon />
          </CircleIconButton>
        </Tooltip>
      </AdaptiveRowHeader>
      <Grid container spacing={1}>
        <Grid item xs={8} sm={10}>
          <SpacedAutocompleteInput
            options={Object.keys(foodItemOptionToIdMap)}
            label="Select food"
            value={
              idToFoodItemOptionMap.get(dishPortionOrder?.food_id ?? 0) ?? ""
            }
            onChange={handleFoodSelectionChange}
            error={!dishPortionOrder?.food_id}
            shrink
          />
        </Grid>
        <Grid item xs={4} sm={2}>
          <SpacedTextInput
            label="Portions"
            type="number"
            value={displayAmount}
            onChange={handleAmountChange}
            error={!Number(displayAmount) || dishPortionOrder.amount <= 0}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PortionOrderInputFormRow;
