import {
  FoodItem,
  SearchFoodItemsFoodItemSearchGetData,
  SearchFoodItemsFoodItemSearchGetResponse,
} from "client/jspPlatformExperiment";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import jspPlatformApi from "client/portals";
import { isApiError, isErrorResponseBodyType } from "models/utils/apiUtils";
import { isFoodItemArray } from "models/food/item/foodItem";
import dayjs, { Dayjs } from "dayjs";
import { RootState } from "store/index";

interface FoodItemOptionsState {
  data: Record<number, FoodItem>;
  updatedAt: Dayjs | null | undefined;
  isLoading: boolean;
  error: string | null | undefined;
}

const initialState: FoodItemOptionsState = {
  data: {},
  updatedAt: null,
  isLoading: false,
  error: null,
};

const fetchAllFoodItemOptions = createAsyncThunk(
  "foodItemOptions/fetchAllFoodItemOptions",
  async (
    input: SearchFoodItemsFoodItemSearchGetData | null | undefined,
    { getState, rejectWithValue },
  ) =>
    jspPlatformApi()
      .foodItem.searchFoodItemsFoodItemSearchGet({
        ...input,
        keywords: null,
        returnKeysOnly: false,
        onlyUpdatedAfter: (
          getState() as RootState
        ).foodItemOptions.updatedAt?.toISOString(),
      })
      .then((result: SearchFoodItemsFoodItemSearchGetResponse) => {
        if (isFoodItemArray(result.data)) {
          return result.data;
        }
        return null;
      })
      .catch((error) => {
        let errMsg: string;
        if (isApiError(error) && isErrorResponseBodyType(error.body)) {
          errMsg = error.body.detail;
        } else {
          errMsg = "Unknown error!";
        }
        return rejectWithValue(errMsg);
      }),
);

const updateFoodItemOptions = (
  state: FoodItemOptionsState,
  action: PayloadAction<FoodItem[] | null>,
): FoodItemOptionsState => {
  return {
    ...state,
    updatedAt: dayjs().subtract(dayjs.duration({ minutes: 10 })),
    data: {
      ...state.data,
      ...action.payload?.reduce<Record<number, FoodItem>>(
        (acc, newFoodItem) => {
          acc[newFoodItem.id] = newFoodItem;
          return acc;
        },
        {},
      ),
    },
  };
};

export const foodItemOptionsSlice = createSlice({
  name: "foodItemOptionsSlice",
  initialState,
  reducers: {
    setFoodItemOptions: updateFoodItemOptions,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllFoodItemOptions.pending, (state) => {
      return { ...state, isLoading: true };
    });
    builder.addCase(fetchAllFoodItemOptions.fulfilled, (state, action) => {
      return {
        ...state,
        ...updateFoodItemOptions(state, action),
        isLoading: false,
      };
    });
    builder.addCase(fetchAllFoodItemOptions.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false,
        error: action.payload as string,
      };
    });
  },
});

export const actions = {
  ...foodItemOptionsSlice.actions,
  fetchAllFoodItemOptions,
};

export default foodItemOptionsSlice.reducer;
