import FileCopyIcon from "@mui/icons-material/FileCopy";
import { FC, useCallback } from "react";
import styled from "styled-components";
import { Menu } from "client/jspPlatformExperiment";
import MenuSectionViewBox from "components/menu/ui/common/MenuSectionViewBox";
import { Paper, Stack } from "@mui/material";
import Icon from "@mui/material/Icon";
import Box from "@mui/material/Box";
import dayjs from "dayjs";
import CommonButton from "components/common/ui/CommonButton";
import { Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Routes from "models/routes";

const StyledMenuPaper = styled(Paper)`
  margin: 0.25rem;
  padding: 0 1rem 1.5rem 0;
  border-radius: 0;
`;

const StyledMenuPaperIcon = styled(Icon)`
  align-items: center;
  color: rgb(100, 100, 100);
`;

const MenuHeader = styled(Box)`
  font-size: 1.3rem;
  font-weight: 100;
  padding: 1rem 1rem 0 1rem;
  color: rgb(100, 100, 100);
`;

const MenuSubHeader = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  font-size: 0.8rem;
  font-weight: 100;
  padding: 0.2rem 0 0 0.5rem;
  color: rgb(144, 144, 144);
`;

interface MenuViewBoxProps {
  menu: Menu;
}

const MenuViewBox: FC<MenuViewBoxProps> = ({ menu }) => {
  const navigate = useNavigate();

  /**
   * Navigate to menu detail page when edit button clicked
   */
  const onEditClick = useCallback(
    (menuId: number) => () => {
      navigate(`${Routes.MENU_EDIT}/${menuId}`, {
        replace: false,
      });
    },
    [navigate],
  );

  return (
    <StyledMenuPaper elevation={3}>
      <MenuHeader>
        <Stack alignItems="center" direction="row" gap={1}>
          <StyledMenuPaperIcon>
            <FileCopyIcon fontSize="small" />
          </StyledMenuPaperIcon>
          {menu.name}
        </Stack>
        <MenuSubHeader>
          Serving Time: {dayjs(menu.serving_datetime).format("H:mm")}
        </MenuSubHeader>
        <CommonButton
          text="Edit"
          positive="true"
          onClick={onEditClick(menu.id)}
        />
      </MenuHeader>
      {menu.sections.map((section) => (
        <MenuSectionViewBox
          key={`${menu.id}-${section.name}`}
          section={section}
        />
      ))}
    </StyledMenuPaper>
  );
};

export default MenuViewBox;
