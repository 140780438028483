import { Grid, InputAdornment } from "@mui/material";
import React, {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  SyntheticEvent,
  useCallback,
} from "react";
import {
  FoodItem,
  IngredientState,
  StorageTemperature,
} from "client/jspPlatformExperiment";
import SpacedTextInput, {
  SpacedAutocompleteInput,
} from "components/common/ui/SpacedTextInput";
import TimeDurationField from "components/common/ui/TimeDurationField";
import dayjs from "dayjs";
import { Duration } from "dayjs/plugin/duration";

interface FoodItemEditSectionProps {
  foodItem: FoodItem;
  setFoodItem: Dispatch<SetStateAction<FoodItem>>;
}

const ingredientCategoriesOptions = [
  "other",
  "meats",
  "seafood",
  "fruits",
  "veggies",
  "grains",
  "spices",
  "dairy",
  "nuts",
  "legumes",
  "standard",
  "alcohol",
  "sides",
  "fungi",
].sort();

const FoodItemBasicInfoEditSection: FC<FoodItemEditSectionProps> = ({
  foodItem,
  setFoodItem,
}) => {
  const handleNameChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setFoodItem({
        ...foodItem,
        food_name: event.target.value,
      });
    },
    [foodItem, setFoodItem],
  );

  const handleStateChange = useCallback(
    (_: SyntheticEvent, newValue: IngredientState | null) => {
      setFoodItem({
        ...foodItem,
        state: newValue !== null ? newValue : IngredientState.NONE,
      });
    },
    [foodItem, setFoodItem],
  );

  const handleCategoryChange = useCallback(
    (_: SyntheticEvent, newValue: string | null) => {
      setFoodItem({
        ...foodItem,
        category: newValue !== null ? newValue : "other",
      });
    },
    [foodItem, setFoodItem],
  );

  const handleStoreTempChange = useCallback(
    (_: SyntheticEvent, newValue: StorageTemperature | null) => {
      setFoodItem({
        ...foodItem,
        storage_temp: newValue,
      });
    },
    [foodItem, setFoodItem],
  );

  const handlePortionSizeChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value ?? "";
      setFoodItem({
        ...foodItem,
        portion_size: newValue !== "" ? Number(newValue) : null,
      });
    },
    [foodItem, setFoodItem],
  );

  /**
   * Handle shelf life change
   */
  const handleShelfLifeChange = useCallback(
    (newShelfLife: Duration) => {
      setFoodItem({
        ...foodItem,
        shelf_life: newShelfLife.toISOString(),
      });
    },
    [foodItem, setFoodItem],
  );

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <SpacedTextInput
          label="Name"
          value={foodItem.food_name}
          onChange={handleNameChange}
          required
        />
      </Grid>
      <Grid item xs={6} md={3} lg={2}>
        <SpacedAutocompleteInput
          label="State"
          value={foodItem.state}
          options={Object.values(IngredientState)}
          onChange={handleStateChange}
        />
      </Grid>
      <Grid item xs={6} md={3} lg={2}>
        <SpacedAutocompleteInput
          label="Category"
          value={foodItem.category}
          options={ingredientCategoriesOptions}
          onChange={handleCategoryChange}
        />
      </Grid>
      <Grid item xs={6} md={3} lg={2}>
        <SpacedAutocompleteInput
          label="Store Temperature"
          value={foodItem.storage_temp}
          options={Object.values(StorageTemperature)}
          onChange={handleStoreTempChange}
        />
      </Grid>
      <Grid item xs={6} md={3} lg={2}>
        <SpacedTextInput
          label="Portion Size"
          value={foodItem.portion_size ?? ""}
          onChange={handlePortionSizeChange}
          type="number"
          InputProps={{
            endAdornment: <InputAdornment position="end">g</InputAdornment>,
          }}
          error={
            foodItem.portion_size
              ? foodItem.portion_size <= 0
              : foodItem.portion_size !== null &&
                foodItem.portion_size !== undefined
          }
        />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <TimeDurationField
          label="Shelf life"
          value={dayjs.duration(foodItem.shelf_life)}
          onChange={handleShelfLifeChange}
        />
      </Grid>
    </Grid>
  );
};

export default FoodItemBasicInfoEditSection;
