import React, {
  FC,
  memo,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FoodItemKey } from "client/jspPlatformExperiment";
import AdaptiveRowHeader from "components/common/ui/AdaptiveRowHeader";
import { Tooltip } from "@mui/material";
import CircleIconButton from "components/common/ui/CircleIconButton";
import CloseIcon from "@mui/icons-material/Close";
import { SpacedAutocompleteInput } from "components/common/ui/SpacedTextInput";
import CommonButton from "components/common/ui/CommonButton";
import { usePromptSnackBar } from "components/common/ui/PromptSnackBarProvider";
import jspPlatformApi from "client/portals";
import ColoredCircularProgress from "components/common/ui/ColorCircularProgress";
import { css } from "styled-components";
import SpacedTextField from "components/common/ui/SpacedTextField";
import { getFoodItemOptionMaps } from "utils/food/foodItemOptionDisplayUtils";

interface RelinkFoodActionFormProps {
  newProductCode: string;
  ingredientOptions: FoodItemKey[];
  onClose?: () => void;
  onRelink?: (msg: string) => void;
}

const RelinkFoodActionForm: FC<RelinkFoodActionFormProps> = ({
  newProductCode,
  ingredientOptions,
  onClose,
  onRelink,
}) => {
  const promptSnackBar = usePromptSnackBar();
  const [foodItemOptionToIdMap, setFoodItemOptionToIdMap] = useState<
    Record<string, number>
  >({ "": 0 });
  const [foodIdToItemOptionMap, setFoodIdToItemOptionMap] = useState<
    Map<number, string>
  >(new Map([[0, ""]]));
  const [foodIdToNameMap, setFoodIdToNameMap] = useState<Map<number, string>>(
    new Map([[0, ""]]),
  );

  const [relinkFoodId, setRelinkFoodId] = useState<number | undefined>(
    undefined,
  );
  const [isPushing, setIsPushing] = useState(false);

  /**
   * Use effect to sync the food item option names
   */
  useEffect(() => {
    const {
      foodItemOptionNamesToIdsMap,
      idsToFoodItemOptionNamesMap,
      idsToFoodNamesMap,
    } = getFoodItemOptionMaps(ingredientOptions);
    setFoodItemOptionToIdMap(foodItemOptionNamesToIdsMap);
    setFoodIdToItemOptionMap(idsToFoodItemOptionNamesMap);
    setFoodIdToNameMap(idsToFoodNamesMap);
  }, [ingredientOptions]);

  /**
   * Get the relink drop-down options
   */
  const relinkFoodOptions = useMemo(
    () => Object.keys(foodItemOptionToIdMap),
    [foodItemOptionToIdMap],
  );

  /**
   * Handle relink food drop down menu change
   */
  const handleRelinkFoodChange = useCallback(
    (_: SyntheticEvent, newValue: string | null) => {
      const foodId =
        newValue !== null && newValue !== ""
          ? foodItemOptionToIdMap[newValue]
          : undefined;
      setRelinkFoodId(foodId);
    },
    [foodItemOptionToIdMap],
  );

  /**
   * Call server API to relink food
   */
  const pushRelinkUpdate = useCallback(async () => {
    if (!relinkFoodId) {
      promptSnackBar.setPromptProps({
        isOpen: true,
        message: "Not food selected to relink.",
        severity: "error",
      });
    } else {
      setIsPushing(true);

      // Update ingredient's supplier info, specifically the product code
      await jspPlatformApi()
        .ingredient.updateIngredientFoodIngFoodIdPatch({
          foodId: relinkFoodId,
          requestBody: {
            supplier_info: {
              product_code: newProductCode,
            },
          },
        })
        .then(() => {
          promptSnackBar.setPromptProps({
            isOpen: true,
            message: "Relink succeed.",
            severity: "success",
            autoHeightDuration: 5000,
          });
          onRelink?.(`Relinked to \`${foodIdToNameMap.get(relinkFoodId)}\``);
        })
        .catch(() => {});

      setIsPushing(false);
    }
  }, [relinkFoodId, promptSnackBar, newProductCode, onRelink, foodIdToNameMap]);

  /**
   * Handle the update in relink component click
   * Wrapping the async function in a synchronous onClick handler
   */
  const handleRelinkUpdateButtonClick = useCallback(() => {
    pushRelinkUpdate().catch((error) => {
      promptSnackBar.setPromptProps({
        isOpen: true,
        message: `Relink failed ${error}.`,
        severity: "error",
      });
      setIsPushing(false);
    });
  }, [pushRelinkUpdate, promptSnackBar]);

  return (
    <>
      <AdaptiveRowHeader switchWidthInPixel={1}>
        <Tooltip title="Not relink">
          {isPushing ? (
            <CircleIconButton disabled sizepx={30} />
          ) : (
            <CircleIconButton
              danger="true"
              positive="true"
              sizepx={30}
              onClick={onClose}
            >
              <CloseIcon />
            </CircleIconButton>
          )}
        </Tooltip>
      </AdaptiveRowHeader>
      {isPushing ? (
        <>
          <SpacedTextField
            label="Choose a food item"
            InputLabelProps={{ shrink: true }}
            sx={{ minWidth: 0 }}
            value={foodIdToItemOptionMap.get(relinkFoodId ?? 0) ?? ""}
            error={!relinkFoodId}
          />
          <AdaptiveRowHeader switchWidthInPixel={1}>
            <ColoredCircularProgress color={css`rgb(200, 200, 200)`} />
          </AdaptiveRowHeader>
        </>
      ) : (
        <>
          <SpacedAutocompleteInput
            label="Choose a food item"
            shrink
            options={relinkFoodOptions}
            sx={{ minWidth: 0 }}
            value={foodIdToItemOptionMap.get(relinkFoodId ?? 0) ?? ""}
            onChange={handleRelinkFoodChange}
            error={!relinkFoodId}
          />
          <CommonButton
            positive="true"
            text="Update"
            onClick={handleRelinkUpdateButtonClick}
          />
        </>
      )}
    </>
  );
};

export default memo(RelinkFoodActionForm);
