import styled from "styled-components";
import Box from "@mui/material/Box";

// eslint-disable-next-line import/prefer-default-export
export const RoundedItemBox = styled(Box)`
  margin-bottom: 0.5rem;
  padding: 1rem 1rem;
  border: rgb(230, 230, 230) solid 0.1rem;
  border-radius: 0.5rem;
`;
