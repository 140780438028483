export enum MassUnit {
  G = "G",
  GA = "GA",
  OZ = "OZ",
  LB = "LB",
  KG = "KG",
  KGA = "KGA",
}

export enum VolumeUnit {
  ML = "ML",
  L = "L",
}

export enum CountingUnit {
  UNIT = "UNIT",
  DZ = "DZ",
  GR = "GR",
}

export const toBaseFactorWeightUnit = {
  [MassUnit.G]: 1,
  [MassUnit.GA]: 1,
  [MassUnit.OZ]: 28.3495,
  [MassUnit.LB]: 450,
  [MassUnit.KG]: 1000,
  [MassUnit.KGA]: 1000,
};

export const toBaseFactorVolumeUnit = {
  [VolumeUnit.ML]: 1,
  [VolumeUnit.L]: 1000,
};

export const toBaseFactorCountingUnit = {
  [CountingUnit.UNIT]: 1,
  [CountingUnit.DZ]: 12,
  [CountingUnit.GR]: 144,
};

export const factorMassUnit = (source: MassUnit, target: MassUnit): number =>
  toBaseFactorWeightUnit[target] / toBaseFactorWeightUnit[source];

export const factorVolumeUnit = (
  source: VolumeUnit,
  target: VolumeUnit,
): number => toBaseFactorVolumeUnit[target] / toBaseFactorVolumeUnit[source];

export const factorCountingUnit = (
  source: CountingUnit,
  target: CountingUnit,
): number =>
  toBaseFactorCountingUnit[target] / toBaseFactorCountingUnit[source];

export const isMassUnit = (o: unknown): o is MassUnit => {
  return !!MassUnit[o as keyof typeof MassUnit];
};

export const isVolumeUnit = (o: unknown): o is VolumeUnit => {
  return !!VolumeUnit[o as keyof typeof VolumeUnit];
};

export const isCountingUnit = (o: unknown): o is CountingUnit => {
  return !!CountingUnit[o as keyof typeof CountingUnit];
};

export const factorKG2Uom = (uom: string): number => {
  let conversionFactor = 0.1;
  if (isMassUnit(uom)) {
    conversionFactor = factorMassUnit(MassUnit[uom], MassUnit.KG);
  } else if (isVolumeUnit(uom)) {
    conversionFactor = factorVolumeUnit(VolumeUnit[uom], VolumeUnit.L);
  } else if (isCountingUnit(uom)) {
    conversionFactor = factorCountingUnit(CountingUnit[uom], CountingUnit.GR); // isn't use for real conversion here
  }
  return conversionFactor;
};
