import styled, { css } from "styled-components";
import TextField, {
  TextFieldProps,
  TextFieldVariants,
} from "@mui/material/TextField";
import { FC } from "react";

export const SpacedTextCSS = css`
  width: 100%;
  font-size: 0.5rem;
  background-color: rgb(255, 255, 255, 80%);
  border-radius: 0.3rem;

  label {
    color: #a0aab4;
  }

  label.Mui-error {
    font-weight: 800;
    border-radius: 0.3rem;
  }

  label.Mui-focused {
    color: #a0aab4;

    &.Mui-error {
      color: red;
    }
  }

  .MuiOutlinedInput-root {
    fieldset {
      border-color: #eee;
    }

    &.Mui-disabled {
      background-color: #eee;

      .MuiOutlinedInput-notchedOutline {
        border-color: #eee;
      }
    }

    &:hover fieldset {
      border-color: #eee;
    }

    &.Mui-focused fieldset {
      border-color: #eee;
    }

    &.Mui-error fieldset {
      border-color: red;
      background-color: rgb(255, 0, 0, 10%);

      legend {
        max-width: initial;
      }
    }
  }
`;

interface StypledSpacedTextFieldProps {
  gray?: boolean;
}

export const StyledSpacedTextField = styled(TextField).withConfig({
  shouldForwardProp: (prop) => !["gray"].includes(prop),
})<StypledSpacedTextFieldProps>`
  display: inline-flex;

  ${SpacedTextCSS}

  ${({ gray }) => {
    let borderColor = css`#eee`;
    if (gray) {
      borderColor = css`rgb(240, 240, 240)`;
      return css`
        background-color: ${borderColor};

        .MuiOutlinedInput-root {
          fieldset {
            border-color: ${borderColor};
          }

          &.Mui-disabled {
            background-color: ${borderColor};

            .MuiOutlinedInput-notchedOutline {
              border-color: ${borderColor};
            }
          }

          &:hover fieldset {
            border-color: ${borderColor};
          }
        }
      `;
    }
    return null;
  }}
` as typeof TextField;

interface SpacedTextFieldProps
  extends Omit<TextFieldProps, "variant">,
    StypledSpacedTextFieldProps {
  variant?: TextFieldVariants;
}

const SpacedTextField: FC<SpacedTextFieldProps> = ({ ...props }) => {
  return (
    <StyledSpacedTextField
      InputProps={{ ...props.InputProps, readOnly: true }}
      {...props}
    />
  );
};

export default SpacedTextField;
