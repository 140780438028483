import styled, { css } from "styled-components";
import Box from "@mui/material/Box";

interface AdaptiveRowHeaderProps {
  switchWidthInPixel?: number;
}

const AdaptiveRowHeader = styled(Box).withConfig({
  shouldForwardProp: (prop) => !["switchWidthInPixel"].includes(prop),
})<AdaptiveRowHeaderProps>`
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 0.5rem;
  margin-right: 0.5rem;

  ${({ switchWidthInPixel }) => {
    const width = !switchWidthInPixel ? 900 : switchWidthInPixel;

    return css`
      @media only screen and (max-width: ${width - 1}px) {
        flex-direction: column;
        background: rgb(244, 244, 244);
        border-radius: 0.5rem;
      }
    `;
  }}
`;

export default AdaptiveRowHeader;
