import { ChangeEvent, Dispatch, FC, SetStateAction, useCallback } from "react";
import { Grid } from "@mui/material";
import { FoodItemNutrition } from "client/jspPlatformExperiment";
import SpacedTextInput from "components/common/ui/SpacedTextInput";

interface FoodItemNutritionEditSectionProps {
  nutritionFacts: FoodItemNutrition;
  setNutritionFacts: Dispatch<SetStateAction<FoodItemNutrition>>;
}

const nutrients: { label: string; name: keyof FoodItemNutrition }[] = [
  { label: "Serving Size (g/ml)", name: "serving_size" },
  { label: "Calories", name: "calories" },
  { label: "Fat / Lipides (g)", name: "fat" },
  { label: "Saturated Fat / Gras saturés (g)", name: "saturated_fat" },
  { label: "Trans Fat / Gras trans (g)", name: "trans_fat" },
  { label: "Carbohydrates / Glucides (g)", name: "carbohydrates" },
  { label: "Fibre / Fibres (g)", name: "fibre" },
  { label: "Sugars / Sucres (g)", name: "sugars" },
  { label: "Protein / Protéines (g)", name: "protein" },
  { label: "Cholesterol / Cholestérol (mg)", name: "cholesterol" },
  { label: "Sodium (mg)", name: "sodium" },
  { label: "Potassium (mg)", name: "potassium" },
  { label: "Calcium (mg)", name: "calcium" },
  { label: "Iron / Fer (mg)", name: "iron" },
  { label: "Data Source", name: "data_source" },
];

const FoodItemNutritionEditSection: FC<FoodItemNutritionEditSectionProps> = ({
  nutritionFacts,
  setNutritionFacts,
}) => {
  const handleNutritionFactsChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const keyInput: string = event.target.name;
      let valueInput: number | string = event.target.value;
      if (
        !valueInput.endsWith(".") &&
        !valueInput.endsWith("0") &&
        !(keyInput === "data_source")
      ) {
        valueInput = Number(valueInput) || valueInput;
      }
      setNutritionFacts({
        ...nutritionFacts,
        [event.target.name]: valueInput,
      });
    },
    [nutritionFacts, setNutritionFacts],
  );

  const validateNutritionFactsField = useCallback(
    (fieldName: string, fieldValue: number | string): boolean => {
      if (fieldName === "data_source") {
        return false;
      } else if (fieldName === "serving_size") {
        return !Number(fieldValue);
      }
      return !Number(fieldValue) && Number(fieldValue) !== 0;
    },
    [],
  );

  return (
    <Grid container spacing={1}>
      {nutrients.map((nutrientTextFieldProps) => (
        <Grid key={nutrientTextFieldProps.name} item xs={12} md={4} lg={3}>
          <SpacedTextInput
            InputLabelProps={{ shrink: true }}
            onChange={handleNutritionFactsChange}
            label={nutrientTextFieldProps.label}
            name={nutrientTextFieldProps.name}
            value={nutritionFacts[nutrientTextFieldProps.name] ?? ""}
            error={validateNutritionFactsField(
              nutrientTextFieldProps.name,
              nutritionFacts[nutrientTextFieldProps.name] ?? "",
            )}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default FoodItemNutritionEditSection;
