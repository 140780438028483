import React, { ChangeEvent, FC, useCallback, useState } from "react";
import { Grid, InputAdornment } from "@mui/material";
import { StockReplenishmentCreateWithFoodItem } from "client/jspPlatformExperiment";
import { Duration } from "dayjs/plugin/duration";
import dayjs from "dayjs";
import SpacedTextField from "components/common/ui/SpacedTextField";
import TimeDurationField from "components/common/ui/TimeDurationField";
import { useAppDispatch } from "hooks/useReduxStore";
import { actions } from "store/slices/inventory/restockingSlice";
import { RoundedItemBox } from "components/common/ui/RoundedItemBox";

interface ReplenishmentActionBoxProps {
  displayKey: string;
  data: StockReplenishmentCreateWithFoodItem;
}

const ReplenishmentActionBox: FC<ReplenishmentActionBoxProps> = ({
  displayKey,
  data,
}) => {
  const dispatch = useAppDispatch();
  const [amount, setAmount] = useState<number | string>(
    Math.round(data.amount * 100) / 100,
  );
  const [totalPrice, setTotalPrice] = useState<number | string>(
    Math.round(data.total_price) / 100,
  );

  const handleTotalAmountChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      const newNumber = Number(newValue);
      setAmount(
        !newNumber || newValue.endsWith(".") || newValue.endsWith("0")
          ? newValue
          : newNumber,
      );
      dispatch(
        actions.updateAReplenishment({
          key: displayKey,
          newData: {
            ...data,
            amount: !newNumber ? 0 : newNumber,
          },
        }),
      );
    },
    [data, dispatch, displayKey],
  );

  const handleTotalPriceChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      const newNumber = Number(newValue);
      setTotalPrice(
        !newNumber || newValue.endsWith(".") || newValue.endsWith("0")
          ? newValue
          : newNumber,
      );
      dispatch(
        actions.updateAReplenishment({
          key: displayKey,
          newData: {
            ...data,
            total_price: !newNumber ? 0 : Math.round(newNumber * 100),
          },
        }),
      );
    },
    [data, dispatch, displayKey],
  );

  const handleShelfLifeChange = useCallback(
    (newShelfLife: Duration) => {
      dispatch(
        actions.updateAReplenishment({
          key: displayKey,
          newData: {
            ...data,
            shelf_life: newShelfLife.toISOString(),
          },
        }),
      );
    },
    [data, dispatch, displayKey],
  );

  return (
    <RoundedItemBox>
      <Grid container spacing={1} columns={24}>
        <Grid item xs={24} md={20} lg={15}>
          <SpacedTextField
            label="Food name"
            value={data.food_item.food_name}
            gray
          />
        </Grid>
        <Grid item xs={8} sm={6} md={4} lg={3}>
          <SpacedTextField
            label="Item code"
            value={data.food_item.food_suppliers![0].product_code}
            gray
          />
        </Grid>
        <Grid item xs={8} sm={6} md={4} lg={3}>
          <SpacedTextField
            label="Total amount"
            value={amount}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: <InputAdornment position="end">g</InputAdornment>,
            }}
            onChange={handleTotalAmountChange}
            error={data.amount <= 0}
          />
        </Grid>
        <Grid item xs={8} sm={6} md={4} lg={3}>
          <SpacedTextField
            label="Total price"
            value={totalPrice}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            onChange={handleTotalPriceChange}
            error={data.total_price <= 0}
          />
        </Grid>
        <Grid item xs={24} sm={18} md={16} lg={11}>
          <TimeDurationField
            label="Shelf life"
            value={dayjs.duration(data.shelf_life)}
            onChange={handleShelfLifeChange}
          />
        </Grid>
      </Grid>
    </RoundedItemBox>
  );
};

export default ReplenishmentActionBox;
